*,
*:before,
*:after {
    box-sizing: border-box;
}

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    background: #fff;
    color: #555;
    border: 24px solid #fff;
}

h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3em;
}

h2 {
    font-size: 32px;
    font-weight: 400;
    margin-top: 50px;
}

h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 20px 0 4px 0;
}

p {
    font-size: 20px;
    line-height: 1.5em;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: #777;
    text-decoration: none;
    border-bottom: 1px solid #ccc;
}

a:hover {
    background: #fff;
}
  
.wht {
    background: #f2f2f2;
    color: #555;
}

.blk {
    background: #000;
    color: #333;
}

.welcome {
    background-color: #f2f2f2;
    min-height: 95vh;
    border-bottom: 24px solid #fff;
}

.about {
    max-width: 50em;
    padding: 2em 3em;
}

.about p {
    font-size: 18px;
}

.about img.photo {
    float: right;
    max-width: 200px;
    border-radius: 50%;
    padding: 2em;
}

.desk {
    background-attachment: fixed;
    background-image: url(/img/desk.jpg);
    background-position: center center;
    background-size: cover;
    background-color: #000;
    height: 500px;
    border-bottom: 20px solid #fff;
}

.work {
    clear: both;
    text-align: center;
}

.work a {
    color: #777;
    background: #f4f4f4;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
}

ul.work,
ul.photos {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.work li {
    width: 50%;
    display: block;
    float: left;
    margin: 0;
    padding: 2em 1em;
    min-height: 400px;
}

.work p {
    margin: 0 auto;
    font-size: 14px;
    max-width: 400px;
}

ul.photos li img {
    width: 33.333333%;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
}

ul.contacts {
    margin: 30px 0 0 0;
    padding: 0;
    list-style-type: none;
}
  
ul.contacts li {
    margin: 14px 0;
    padding: 0;
    display: inline;
}
  
ul.contacts li img {
    max-width: 18px;
    margin-right: 4px;
}

ul.contacts li svg {
    fill: currentColor;
}
  
ul.contacts li a {
    border-bottom: none;
    position: relative;
    top: 0;
    transition: top ease 0.2s;
}
  
ul.contacts li a:hover {
    background: none;
    top: -3px;
}

.footer {
    background: #fff;
    padding: 1em;
}

.footer p {
    text-align: center;
    color: #777;
    font-size: 14px;
}

@media (max-width: 800px) {
    .about,
    ul.work li {
        width: 100%;
        float: none;
    }
    ul.work li {
        min-height: 100%;
    }
    h1 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3em;
    }
    h2 {
        font-size: 18px;
    }
    .about p {
        font-size: 16px;
    }
    .about {
        padding: 2em;
    }
    .about img.photo {
        float: none;
        text-align: center;
        max-width: 200px;
        border-radius: 50%;
        padding: 1em;
        display: block;
        margin: 0 auto;
    }
    .desk {
        display: none;
    }
}
